<template>
  <div>
    <b-card v-if="coupon.id">
      <div class="row">
        <div class="col-lg-6">
          <h3>
            {{ coupon.label }}
            <br />
            <small>
              Mise à jour le
              {{ $dayjs(coupon.updated_at).format('DD-MM-YYYY à HH:mm') }}
            </small>
          </h3>
        </div>
        <div class="col-lg-6 text-right">
          <el-popconfirm
            v-if="coupon.status == 'ENABLED'"
            title="Confirmez-vous l'annulation de ce coupon ?"
            confirm-button-text="OK"
            cancel-button-text="Annuler"
            @confirm="disableCoupon()"
          >
            <button slot="reference" class="btn btn-danger btn-sm mr-1">
              Annuler le coupon
            </button>
          </el-popconfirm>
          <button
            v-if="
              coupon.scope.includes('PRIVATE_') == true &&
              coupon.status == 'ENABLED'
            "
            class="btn btn-info btn-sm"
            @click.prevent="
              displayGiftCardNotification = !displayGiftCardNotification
            "
          >
            Renvoyer le message
          </button>
        </div>
      </div>
      <div v-if="displayGiftCardNotification" class="border m-3 p-3">
        <div class="p-l-20 p-r-20">
          <h2 class="h3">Renvoyer le message</h2>
          <p>Renvoyer le message de notification ou destinataire du cadeau</p>
          <button
            class="btn btn-sm btn-danger"
            @click.prevent="sendGiftCardNotification()"
          >
            Transmettre
          </button>
        </div>
      </div>
      <b-tabs content-class="mt-2">
        <b-tab title="Propriétés" active>
          <div class="row">
            <div class="col-md-11">
              <div v-if="displayCouponForm == true" class="m-lg-2 mb-2">
                <h3>Modifier le coupon</h3>
                <coupon-form
                  :coupon-id="$route.params.id"
                  :display.sync="displayCouponForm"
                  @updated="init()"
                />
              </div>
              <coupon-card v-else :coupon="coupon" />
            </div>
            <div class="col-md-1 text-right">
              <button
                v-if="coupon.status == 'ENABLED'"
                class="btn btn-primary btn-sm"
                @click.prevent="displayCouponForm = !displayCouponForm"
              >
                <feather-icon icon="EditIcon" />
              </button>
            </div>
          </div>
        </b-tab>
        <b-tab title="Métadonnées">
          <pre>{{ coupon }}</pre>
        </b-tab>
      </b-tabs>
    </b-card>
    <b-card v-if="coupon.id">
      <h4 class="mb-2">
        Dernières commandes associées à ce coupon
        <small v-if="orders.length > 0">
          - {{ pagination.total }} commandes
        </small>
      </h4>
      <p v-if="orders.length == 0" class="text-center">
        <em class="text-muted">Pas de commande pour l’instant</em>
      </p>
      <table class="table table-hover">
        <tbody>
          <template v-for="order in orders">
            <tr
              :key="order.id"
              @click.prevent="$router.push(`/orders/${order.id}`)"
            >
              <td>
                <h4>
                  {{ $dayjs(order.created_at).format('DD-MM-YYYY') }}
                </h4>
              </td>
              <td>
                {{ $t(`ORDER.${order.status}`) }}
              </td>
              <td>
                {{ order.user.fullname }}
              </td>
              <td class="text-right">
                {{ order.grand_total | currency }}
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </b-card>
    <debug>{{ coupon }}</debug>
  </div>
</template>

<script>
import { BCard, BTabs, BTab } from 'bootstrap-vue'
import CouponCard from '@/components/CouponCard.vue'
import CouponForm from '@/components/CouponForm.vue'

const CouponFields = '*'

const orderFields =
  'results{' +
  'id,status,created_at,grand_total,' +
  'user{fullname},' +
  '},meta'

export default {
  components: {
    BTabs,
    BTab,
    BCard,
    CouponCard,
    CouponForm,
  },
  data() {
    return {
      displayGiftCardNotification: false,
      displayCouponForm: false,
      coupon: {},
      pagination: {},
      orders: [],
    }
  },
  async created() {
    this.init()
  },
  methods: {
    async init() {
      const resp = await this.$http.get(`/coupons/${this.$route.params.id}/`, {
        headers: {
          'X-Fields': CouponFields,
        },
      })
      this.coupon = resp.data

      const respOrders = await this.$http.get('/orders/', {
        params: {
          per_page: 25,
          coupon_id: this.$route.params.id,
          status: 'COMPLETED',
        },
        headers: {
          'X-Fields': orderFields,
        },
      })
      this.orders = respOrders.data.results
      this.pagination = respOrders.data.meta
    },
    async sendGiftCardNotification() {
      if (this.coupon.scope.includes('PRIVATE_') === true) {
        const respCsrf = await this.$http.get('/auth/csrf/')
        const resp = await this.$http.post(
          `/coupons/${this.$route.params.id}/notification/`,
          {},
          {
            headers: {
              'X-Fields': CouponFields,
              'X-CSRF-TOKEN': respCsrf.data.csrf,
            },
          }
        )
        this.coupon = resp.data
        this.displayGiftCardNotification = false
      }
    },
    async disableCoupon() {
      await this.$http.put(
        `/coupons/${this.$route.params.id}/`,
        { status: 'CANCELED', public_id: `${this.coupon.public_id}-DISABLED` },
        {
          headers: {
            'X-Keys': 'status,public_id',
            'X-Fields': CouponFields,
          },
        }
      )
      this.init()
    },
  },
}
</script>
