<template>
  <dl class="row">
    <dt class="col-sm-3 text-right text-muted">#</dt>
    <dd class="col-sm-9">
      <b><copy-value :value="coupon.id" /></b>
    </dd>
    <dt class="col-sm-3 text-right text-muted">Statut</dt>
    <dd class="col-sm-9">
      <b>{{ $t(`COUPON.${coupon.status}`) }}</b>
    </dd>
    <dt class="col-sm-3 text-right text-muted">Code</dt>
    <dd class="col-sm-9">
      <span>
        <b><copy-value :value="coupon.public_id" /></b>
      </span>
    </dd>
    <dt class="col-sm-3 text-right text-muted">Type de réduction</dt>
    <dd class="col-sm-9">
      <span>
        {{ $t(`COUPON.${coupon.scheme}`) }}
      </span>
      <span v-if="coupon.scheme == 'VOUCHER'">
        (offert par
        <router-link :to="`/users/${coupon.attributes.sponsor.user_id}`">
          {{ coupon.attributes.sponsor.fullname }} </router-link
        >)
      </span>
    </dd>
    <dt class="col-sm-3 text-right text-muted">Valeur</dt>
    <dd class="col-sm-9">
      <span>
        {{ coupon.amount_formated }}
        <span v-if="coupon.amount_type == 'PERCENT'">%</span>
        <span v-if="coupon.amount_type == 'FIXED_AMOUNT'">€</span>
      </span>
    </dd>
    <dt class="col-sm-3 text-right text-muted">Utilisation</dt>
    <dd class="col-sm-9">
      <span>
        {{ $t(`COUPON.${coupon.usage_scheme}`) }}
      </span>
    </dd>
    <dt class="col-sm-3 text-right text-muted">
      Nbr de fois où le coupon s'applique
    </dt>
    <dd class="col-sm-9">
      <span v-if="coupon.usage_limit === 0"> Illimité </span>
      <span v-else> {{ coupon.usage_limit }} </span>
    </dd>
    <dt class="col-sm-3 text-right text-muted">S’applique à</dt>
    <dd class="col-sm-9">
      <span
        v-for="scope in coupon.scopes"
        :key="scope"
        class="badge badge-dark mr-1 mb-1"
      >
        {{ $t(`COUPON.${scope}`) }}
      </span>
    </dd>
    <dt class="col-sm-3 text-right text-muted">Notes</dt>
    <dd class="col-sm-9">
      {{ coupon.description || '-' }}
    </dd>
  </dl>
</template>

<script>
export default {
  name: 'UserCard',
  props: {
    coupon: {
      type: Object,
      default() {
        return {}
      },
    },
  },
}
</script>
